import React, { Component } from "react";
import { APP_NAME, BASE_APP_URL, WEBSITE_VERSION } from "../config/constants";
import { priceFormat } from "../utils/misc";

const Pricing = (props) => {
  const plans = [
    // {
    //   id: 1,
    //   active: false,
    //   icon: <img src={`/assets/global/images/membership/1.png?v=${WEBSITE_VERSION}`} style={{ width: 32, height: 'auto' }} alt="logo" />,
    //   title: 'ES Basic',
    //   description: '',
    //   price: 59,
    //   featureList: [
    //     "1 Store Integration",
    //     "1 Social Channel Integration",
    //     "1 Ad account integration",
    //     "Pixel Attribution up to 500 events",
    //     "Profit Tracker/Calculator"
    //   ],
    // },
    {
      id: 2,
      active: true,
      icon: <img src={`/assets/global/images/membership/2.png?v=${WEBSITE_VERSION}`} style={{ width: 32, height: 'auto' }} alt="logo" />,
      title: 'ES Advanced',
      description: '',
      price: 99,
      period: 'month',
      featureList: [
        "Integrate Up to 3 Stores",
        "Integrate Up to 3 Ad Accounts",
        "Profit Tracker/Calculator",
        "E-Scout Pixel Attribution",
        "GPT-4 Powered Ad Management"
      ],
    },
    {
      id: 3,
      active: false,
      icon: <img src={`/assets/global/images/membership/3.png?v=${WEBSITE_VERSION}`} style={{ width: 32, height: 'auto' }} alt="logo" />,
      title: 'ES Annual',
      description: '',
      price: 999,
      period: 'year',
      featureList: [
        "Integrate Up to 3 Stores",
        "Integrate Up to 3 Ad Accounts",
        "Profit Tracker/Calculator",
        "E-Scout Pixel Attribution",
        "GPT-4 Powered Ad Management"
      ],
    }
  ];

  return (
    <section className="pricing-one" id="pricing">
      <div className="container">
        <div className="block-title text-center">
          <h2 className="block-title__title">
            Choose <span>Pricing Plans</span> Which <br /> Suits Your Needs.
          </h2>
        </div>

        <h3 className="text-center landing-subtitle-2 mb-5">
          <span style={{ fontWeight: 500 }}>Package Pricing Plan That Suits Your Needs!</span>
        </h3>

        <div className="tabed-content">
          <div id="month">
            <div className="row pricing-one__price-row">

              {plans.map((plan, index) => (
                <div className={`col-lg-6 animated fadeInUp pricing-one__price-item  ${plan.active === true ? 'active' : ''}`} data-wow-duration="1500ms" key={index}>
                  <div className="pricing-one__single text-center">
                    <h3>${priceFormat(plan.price)}<span className="plan-duration">/{plan.period}</span></h3>
                    <p>{plan.title}</p>
                    <ul className="list-unstyled">
                      {plan.featureList.map((list, i) => (
                        <li key={i}>{list}</li>
                      ))}
                    </ul>
                    <a href={`${BASE_APP_URL}/register`} className="thm-btn pricing-one__btn">
                      <span>Choose Plan</span>
                    </a>
                    <span className="tag-line">No hidden charges!</span>
                  </div>
                </div>
              ))}

            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Pricing;
