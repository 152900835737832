import React from "react";
import { APP_NAME, BASE_APP_URL } from "../config/constants";
//import mocImage from "../assets/images/mocs/cta-moc-2-1.png";
// import mocImage from "../assets/images/home/dog.png";
const mocImage = "/assets/images/home/dog.png";

const CallToActionTwo = (props) => {
  const { showButton = true } = props

  return (
    <section className="cta-two">
      <div className="container">
        <img src={mocImage} className="cta-two__moc" alt="alter text" />
        <div className="row">
          <div className="col-lg-6">
            <div className="cta-two__content">
              <i className="cta-two__icon dimon-icon-data"></i>
              <div className="block-title text-left">
                <h2 className="block-title__title">
                  Enhance your marketing efforts with accurate <span>pixel attribution!</span>
                </h2>
              </div>
              <div className="cta-two__text">
                <p>
                  {APP_NAME}'s pixel attribution feature provides you with reliable and insightful data for every campaign, ad, and ad set.
                  This powerful tool allows you to understand your ad spend, revenue, and Return on Ad Spend (ROAS) across all active channels...
                </p>
                <p>
                  Our aim is to make sure that you make informed decisions for profitable growth.
                  With {APP_NAME}, you can trust in your data, and confidently navigate your path to success.
                </p>
              </div>
              {
                (showButton) ? (
                  <>
                    <div className="text-center-xs">
                      <a href={`${BASE_APP_URL}/register`} className="thm-btn">
                        <span>Get Started</span>
                      </a>
                    </div>
                  </>
                ) : (
                  <></>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default CallToActionTwo;
