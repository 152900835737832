import React from "react";
import { BASE_APP_URL } from "../config/constants";
import bgImage from "../assets/images/background/cta-one-bg.png";
//import mocImage from "../assets/images/mocs/cta-moc-1-1.png";
// import mocImage from "../assets/images/home/bot.png";
const mocImage = "/assets/images/home/bot.png";

const CallToAction = (props) => {
  const { showBgImage = true, showButton = true } = props
  return (
    <section className="cta-one">
      {
        (showBgImage) ? (
          <>
            <img src={bgImage} className="cta-one__bg" alt="alter text" />
          </>
        ) : (
          <></>
        )
      }

      <div className="container">
        <img src={mocImage} className="cta-one__moc" alt="alter text" />

        <div className="row justify-content-lg-end">
          <div className="col-lg-6">
            <div className="cta-one__content">
              <i className="cta-one__icon dimon-icon-data1"></i>
              <div className="block-title text-left">
                <h2 className="block-title__title">
                  Powerful <span>Tools</span> To Help You Make the MOST Amount of Money!
                </h2>
              </div>
              <div className="cta-one__text">
                <p>
                  Our innovative platform leverages AI and automation, transforming your data into actionable insights for driving profitability.
                  With our suite of tools, you can effortlessly manage and automate analytics, merchandising, and forecasting, all while keeping your finger on the pulse of your operation.
                </p>
              </div>
              <div className="landing-item-list">
                <ul className="list-unstyled">
                  <li>
                    <i className="fa fa-check"></i>Leverage AI and automation for data-driven decision making
                  </li>
                  <li>
                    <i className="fa fa-check"></i>Seamlessly manage analytics, merchandising, and forecasting
                  </li>
                  <li>
                    <i className="fa fa-check"></i>Maximize your e-commerce profitability with our comprehensive platform
                  </li>
                </ul>
              </div>
              {
                (showButton) ? (
                  <>
                    <div className="text-center-xs">
                      <a href={`${BASE_APP_URL}/register`} className="thm-btn">
                        <span>Get Started</span>
                      </a>
                    </div>
                  </>
                ) : (
                  <></>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default CallToAction;
