import React from "react";
import AccordionItem from "./AccordionItem";
import { APP_NAME } from "../config/constants";
import bgImage from "../assets/images/background/faq-one-bg.png";
// import avatarLeftImage from "../assets/images/home/anatoly.png";
// import avatarRightImage from "../assets/images/home/nex.png";

const avatarLeftImage = "/assets/images/home/anatoly.png";
const avatarRightImage = "/assets/images/home/nex.png";

const About = () => {
  return (
    <section className="about-one" id="about">
      <img src={bgImage} alt="alter text" className="faq-one__bg about-one__bg" />
      <div className="container container-xl">
        <div className="block-title text-center">
          <h2 className="block-title__title">
            About Us
          </h2>
        </div>

        <div className="about-content">
          <div className="about-content-avatar-absolute about-content-avatar about-content-avatar-left">
            <img src={avatarLeftImage} className="about-avatar" alt="Anatoliy Labinskiy" />
            <p className="avatar-name">Anatoliy Labinskiy</p>
          </div>
          <div className="about-content-text">
            <div className="accrodion-grp faq-accrodion">
              <div className="accrodion active">
                <div className="accrodion-inner">
                  <div className="accrodion-title" role="button" tabIndex="0">
                    <h4>About the Founders:</h4>
                  </div>
                  <div className="accrodion-content animated fadeInUp">
                    <div className="inner">
                      <p>
                        EcomScout was founded by Anatoliy Labinskiy and Mark Mardaa, successful entrepreneurs with a rich history in business.
                        Anatoliy, with over 6 years in the e-commerce space, identified a significant challenge for e-commerce business owners - the importance of maintaining favorable margins.
                        He realized that to run a successful store in a highly competitive environment, owners often had to spend significant amounts on various tools and software.
                        This, unfortunately, could impinge on their margins. Understanding this predicament, Anatoliy and Mark joined forces to create a solution.
                        With Mark's vast expertise in artificial intelligence and his ownership of multiple IT companies, the immense potential of their collaboration became evident in no time.
                        The possibilities that arise from their collective synergy are truly extraordinary.
                        Their goal was simple – make it easier and cheaper for e-commerce store owners to manage their operations, thereby improving their margins.
                        This way, owners could allocate more resources to advertising, potentially driving more revenue.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="avatar-list-box-sm">
              <div className="row">
                <div className="col-lg-6">
                  <div className="about-content-avatar-sm">
                    <img src={avatarLeftImage} className="about-avatar" alt="Anatoliy Labinskiy" />
                    <p className="avatar-name">Anatoliy Labinskiy</p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="about-content-avatar-sm">
                    <img src={avatarRightImage} className="about-avatar" alt="Mark Mardaa" />
                    <p className="avatar-name">Mark Mardaa</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="about-content-avatar about-content-avatar-right">
            <img src={avatarRightImage} className="about-avatar" alt="Mark Mardaa" />
            <p className="avatar-name">Mark Mardaa</p>
          </div>
        </div>

      </div>
    </section>
  );
};
export default About;
