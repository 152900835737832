import React, { useEffect, useState } from "react";
import { apiCheckEmailExist, apiSubscribeEmail } from "../services/authService";
import { console_log, empty } from "../utils/misc";
import { showToast } from "../utils/utils";
import { BASE_APP_URL } from "../config/constants";

const BannerMoc = "/assets/images/home/mobile.png";
const COUPON_CODE = "SCOUT101"

const Banner = () => {
  useEffect(() => {

  }, []);

  const [loading, setLoading] = useState(false)

  const defaultFromData = {
    email: "",
    submit: null
  }
  const [formData, setFormData] = useState(defaultFromData)

  const checkFormValidation = (form_data, current_field_name = "") => {
    let isValid = true
    if (empty(form_data['email'])) {
      isValid = false
    }
    return isValid
  }

  const onChangeFormField = (e, field_name) => {
    const form_data = { ...formData }
    form_data[field_name] = e.target.value
    setFormData(form_data)
  }

  const onSubmit = (event) => {
    event.preventDefault();
    const isValid = checkFormValidation({ ...formData })
    console_log("isValid:::", isValid)
    if (!isValid) {
      return false
    }
    onSubmitFormData(formData)
  }

  const onSubmitFormData = async (values) => {
    setLoading(true)
    const apiRes = await apiCheckEmailExist(values);
    console_log("apiRes::::", apiRes);
    setLoading(false)
    if (apiRes['status'] === '1') {
      showToast("Email is already taken", 'error');
    } else {
      if (typeof window !== 'undefined') {
        window.location.href = `${BASE_APP_URL}/register?coupon=1&email=${values['email']}`;
      }
    }
  }

  return (
    <section className="banner-one" id="home">
      <span className="banner-one__shape-1"></span>
      <span className="banner-one__shape-2"></span>
      <span className="banner-one__shape-3"></span>
      <span className="banner-one__shape-4"></span>
      <div className="container">
        <div className="hide-xs">
          <div className="banner-one__moc">
            <img src={BannerMoc} alt="alter text" />
          </div>
        </div>
        <div className="row">
          <div className="col-xl-6 col-lg-8">
            <div className="banner-one__content">
              <h1 className="banner-one__title">
                Take Your Store To The Next Level with our cutting-edge <br />
                <span>AI-powered Data Platform!</span>
              </h1>
              <p className="banner-one__text">
                EcomScout revolutionizes brand management on Shopify with its cutting-edge AI Data Platform.
                Seamlessly control analytics, attribution, and creative elements, all at your fingertips.
                Stay ahead of the game and drive success with unparalleled convenience and efficiency.
              </p>
              {/* <p className="">
                Start Your 7-Day Trial Today For Just $1!
              </p> */}
              <form className="mailchimp-one__mc-form mc-form mailchimp-one__mc-form-coupon" onSubmit={onSubmit}>
                <label htmlFor="mc-email" className="sr-only">
                  Add email
                </label>
                <input
                  name="email"
                  type="email"
                  id="mc-email"
                  className="formInput"
                  placeholder="Enter your email to start now!"
                  value={formData['email']}
                  onChange={(e) => onChangeFormField(e, 'email')}
                  required={true}
                />
                <button type="submit" disabled={loading}>
                  <i className="dimon-icon-right-arrow"></i>
                </button>
              </form>
            </div>
          </div>
        </div>

        <div className="show-xs">
          <div className="banner-one__moc">
            <img src={BannerMoc} alt="alter text" />
          </div>
        </div>

        <div className="show-xs">
          <div className="mt-4 text-center">
            <a href={`${BASE_APP_URL}/register`} className="banner-one__btn thm-btn">
              <span>Get Started</span>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Banner;
