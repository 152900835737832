import React, { useState } from "react";
import Swiper from "react-id-swiper";
import "swiper/css/swiper.css";
import testiImage1 from "../assets/images/resources/testi-1-1.png";
import { APP_NAME } from "../config/constants";

const Testimonial = () => {
  const [swiper, setSwiper] = useState(null);

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  };

  return (
    <section className="testimonials-one">
      <div className="container">
        <div className="block-title text-center">
          <h2 className="block-title__title">
            What Our <span>Clients</span> Say <br /> About Us.
          </h2>
        </div>
        <div className="testimonials-one__carousel-outer">
          <div className="testimonials-one__carousel">
            <Swiper getSwiper={setSwiper}>
              <div className="item">
                <div className="testimonials-one__single">
                  <div className="testimonials-one__inner">
                    <p>
                      {APP_NAME} has been a game-changer for my online store.
                      Their easy-to-use dashboard and powerful analytics tools have made managing my business a breeze.
                      I've been able to increase my advertising spend thanks to the improved margins.
                      Highly recommended for any e-commerce entrepreneur!
                    </p>
                    <h3>Sarah M. - Online Boutique Owner</h3>
                    <span>Happy {APP_NAME} User</span>
                    {/* <img src={testiImage1} alt="alter text" /> */}
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="testimonials-one__single">
                  <div className="testimonials-one__inner">
                    <p>
                      These guys have revolutionized the way I view my ad campaigns.
                      I finally have accurate data that I can trust, allowing me to make better decisions for my business.
                      The increase in revenue has been noticeable. <br />Thank you, {APP_NAME}!
                    </p>
                    <h3>James K. - Digital Marketer</h3>
                    <span>Happy {APP_NAME} User</span>
                    {/* <img src={testiImage1} alt="alter text" /> */}
                  </div>
                </div>
              </div>

              <div className="item">
                <div className="testimonials-one__single">
                  <div className="testimonials-one__inner">
                    <p>
                      Running my online store so much easier actually...
                      the suite of tools they provide has not only improved my store's profitability but has also given me more time to focus on other aspects of my business.
                      I can't imagine going back to the way things were before {APP_NAME}.
                    </p>
                    <h3>Maria G. - E-commerce Store Owner</h3>
                    <span>Happy {APP_NAME} User</span>
                    {/* <img src={testiImage1} alt="alter text" /> */}
                  </div>
                </div>
              </div>
            </Swiper>
          </div>
          <div className="testimonials-one__carousel__shape-one"></div>
          <div className="testimonials-one__carousel__shape-two"></div>
          <div className="testimonials-one__nav">
            <div
              onClick={goPrev}
              onKeyDown={goPrev}
              role="button"
              tabIndex="-1"
              className="testimonials-one__nav-left"
            >
              <i className="dimon-icon-left-arrow"></i>
            </div>
            <div
              onClick={goNext}
              onKeyDown={goNext}
              role="button"
              tabIndex="-1"
              className="testimonials-one__nav-right"
            >
              <i className="dimon-icon-right-arrow"></i>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Testimonial;
