import React from "react";
import { Link } from "gatsby";
import blog1 from "../assets/images/blog/blog-1-1.jpg";
const blogList = [
  {
    id: 1,
    title: "How to Run Ads on TikTok",
    desc: "",
    last_updated: "June 19, 2023",
    uri: "/blog/get-started-with-tiktok-ads"
  },
  {
    id: 2,
    title: "How to Run Ads on TikTok",
    desc: "",
    last_updated: "June 19, 2023",
    uri: "/blog/get-started-with-tiktok-ads"
  },
  {
    id: 3,
    title: "How to Run Ads on TikTok",
    desc: "",
    last_updated: "June 19, 2023",
    uri: "/blog/get-started-with-tiktok-ads"
  },
  {
    id: 4,
    title: "How to Run Ads on TikTok",
    desc: "",
    last_updated: "June 19, 2023",
    uri: "/blog/get-started-with-tiktok-ads"
  },
  {
    id: 5,
    title: "How to Run Ads on TikTok",
    desc: "",
    last_updated: "June 19, 2023",
    uri: "/blog/get-started-with-tiktok-ads"
  },

]

const NewsHome = () => {
  return (
    <section className="blog-one blog-one__home thm-gray-bg" id="news">
      <div className="container">
        <div className="block-title text-center">
          <h2 className="block-title__title">
            Our Blog
          </h2>
        </div>
        <div className="row">
          {
            blogList.map((item, index) => {
              return (
                <div
                  className="col-lg-4 col-md-12 col-sm-12 wow fadeInUp"
                  data-wow-duration="1500ms"
                >
                  <div className="blog-one__single">
                    <div className="blog-one__image">
                      <img src={`/assets/images/home/blog/${item.id}/thumb.jpg`} alt={item.title} />
                      <Link to={item.uri} className="blog-one__more-link">
                        <i className="fa fa-link"></i>
                      </Link>
                    </div>
                    <div className="blog-one__content">
                      <ul className="list-unstyled blog-one__meta">
                        <li>
                          <a href="#none">{item.last_updated}</a>
                        </li>
                      </ul>
                      <h3 className="blog-one__title">
                        <Link to={item.uri}>
                          Pre and post launch mobile app marketing pitfalls to avoid
                        </Link>
                      </h3>
                      <Link to={item.uri} className="blog-one__link">
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </section>
  );
};
export default NewsHome;
